export const PUBLIC_PAGES_ROUTES = {
  SHOWROOMS: '/showrooms',
}

export const PRIVATE_PAGES_ROUTES = {
  ADDRESS: '/account/addresses',
  ORDERS: '/account/orders',
  PRIVACY: '/account/privacy',
  PROFILE: '/account/profile',
}

export const AUTH_PAGES_ROUTES = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
}
