<script setup lang="ts">
const router = useRouter()

const props = defineProps({
  error: Object,
})

onBeforeMount(() => {
  console.error('error page', { ...props.error })
  router.replace('/page-not-found')
})
</script>
