import { fetchFromNewApi } from '~/helpers/api.ts'
import { useNewSettingsStore } from '~/store/newSettingsStore.ts'

import { newsletterSignup } from '~/api/marketing'

export const useOnlineAppointments = defineStore('onlineAppointments', () => {
  const { currentCountry, currentCurrency, currentLanguage } = useNewSettingsStore()

  const Interests = [
    { id: 24, label: ['engagement-rings', 'Engagement Rings'] },
    { id: 45, label: ['wedding-eternity-rings', 'Wedding and Eternity Rings'] },
    { id: 48, label: ['other-jewellery', 'Other Jewellery'] },
    { id: 27, label: ['only-diams-gems', 'Only Diamonds and Gemstones'] },
  ]

  const CurrentStep = ref('firstStep')
  const Showrooms = ref(null)
  const CurrentShowroom = reactive({})
  const SelectedInterest = ref(null)
  const DisabledDatesStatus = ref(null)
  const DisabledDates = ref(null)
  const SelectedDate = ref()
  const PreferedTime = ref(null)
  const UserData = reactive({
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneCode: currentCountry?.CountryCode,
    PhoneNumber: '',
  })
  const AppointmentId = ref(null)
  const ErrorMessage = ref(false)

  const resetBookingAppointmentState = () => {
    CurrentStep.value = 'firstStep'
    Showrooms.value = null
    Object.assign(CurrentShowroom, {})
    SelectedInterest.value = null
    DisabledDatesStatus.value = null
    DisabledDates.value = null
    SelectedDate.value = new Date()
    PreferedTime.value = null
    Object.assign(UserData, {
      FirstName: '',
      LastName: '',
      Email: '',
      PhoneCode: currentCountry?.CountryCode,
      PhoneNumber: '',
    })
    AppointmentId.value = null
    ErrorMessage.value = false
  }

  const getShowrooms = async () => {
    Showrooms.value = await fetchWithLoader('/api/v1/showrooms/closest')
  }

  const setStep = (step) => {
    if (!CurrentShowroom.IsOpen && CurrentStep.value == 'fourthStep' && step == 'thirdStep')
      CurrentStep.value = 'secondStep'
    else CurrentStep.value = step
  }

  const selectShowroom = ({ showroom }) => {
    Object.assign(CurrentShowroom, showroom)
    SelectedDate.value = null

    if (showroom.IsOpen) CurrentStep.value = 'thirdStep'
    else CurrentStep.value = 'fourthStep'
  }

  const getDisabledDates = async (currentPage) => {
    const firstDay = currentPage.monthComps.firstDayOfMonth
    const lastDay = currentPage.nextMonthComps.firstDayOfMonth

    DisabledDatesStatus.value = 'pending'

    const disabledDatesData = await fetchFromNewApi('/api/v1/booking/calendar-availability', {
      params: {
        CollectionPlaceId: CurrentShowroom.Id,
        StartDay: firstDay?.toJSON().split('T')[0],
        EndDay: lastDay?.toJSON().split('T')[0],
        ProductTypeId: SelectedInterest.value,
      },
    })

    DisabledDates.value = disabledDatesData
      .filter((d) => !d.IsAvailable)
      .map((d) => new Date(d.Date.replace('+00:00', '')))

    if (!SelectedDate.value) {
      SelectedDate.value = disabledDatesData.find((d) => d.IsAvailable)?.Date
    }

    DisabledDatesStatus.value = ''
  }

  const selectDate = ({ preferedTime }) => {
    PreferedTime.value = preferedTime
    CurrentStep.value = 'fourthStep'
  }

  const showroomNotOpened = async () => {
    // const captchaResponse = await useCaptcha().getToken()

    try {
      const result = await newsletterSignup({
        email: UserData.Email,
        firstName: UserData.FirstName,
        lastName: UserData.LastName,
        // isMale: isMale,
        // countryId: currentCountry?.Id,
        // currencyId: currentCurrency?.Id,
        // languageId: currentLanguage?.Id,
        eventName: `showroomNotOpened${CurrentShowroom.City}`,
        url: window.location.href,
      })

      setStep('confirmation')
    } catch (e) {
      ErrorMessage.value = e.data.message
    }
  }

  const selectUser = async () => {
    const captchaResponse = await useCaptcha().getToken()

    try {
      const appointment = await fetchWithLoader('/api/v1/booking/save-appointment-step1', {
        method: 'POST',
        body: {
          CodePhone: UserData.PhoneCode,
          Phone: UserData.PhoneNumber,
          Email: UserData.Email,
          FullName: `${UserData.FirstName} ${UserData.LastName}`,
          CollectionPlace: CurrentShowroom.Id,
          OptedIn: true,
          CaptchaResponse: captchaResponse,
          AppointmentType: 2,
          EnquiryType: 1,
          VirtualMeeting: false,
          VirtualMeetingLangId: 0,
          VirtualMeetingTechId: 0,
          ProductTypeId: SelectedInterest.value,
          PickedProductTypes: [SelectedInterest.value],
          AppointmentDate: SelectedDate.value?.toJSON().split('T')[0],
          PreferedTime: PreferedTime.value,
        },
      })

      AppointmentId.value = appointment.AppointmentId

      setStep('lastStep')
    } catch (e) {
      ErrorMessage.value = e.data.message
    }
  }

  const setAppointment = async ({ appointmentData }) => {
    try {
      const appointment = await fetchWithLoader('/api/v1/booking/save-appointment-step2', {
        method: 'POST',
        body: {
          appointmentId: AppointmentId.value,
          countryId: currentCountry?.Id,
          currencyId: currentCurrency?.Id,
          languageId: currentLanguage?.Id,
          needWhen: appointmentData.needIt.id,
          notes: appointmentData.notes,
          priceMax: appointmentData.price[0],
          priceMin: appointmentData.price[1],
          productTypeId: SelectedInterest.value,
          shapes: appointmentData.shapes.map((s) => s.id),
          stoneType: appointmentData.stoneType ? [appointmentData.stoneType?.id] : null,
        },
      })

      AppointmentId.value = appointment.AppointmentId
      setStep('confirmation')
    } catch (e) {
      ErrorMessage.value = e.data.message
    }
  }

  const closeAlert = () => {
    ErrorMessage.value = false
  }

  return {
    CurrentStep,
    Showrooms,
    CurrentShowroom,
    Interests,
    SelectedInterest,
    DisabledDatesStatus,
    DisabledDates,
    SelectedDate,
    PreferedTime,
    UserData,
    AppointmentId,
    ErrorMessage,
    resetBookingAppointmentState,
    getShowrooms,
    setStep,
    selectShowroom,
    getDisabledDates,
    selectDate,
    showroomNotOpened,
    selectUser,
    setAppointment,
    closeAlert,
  }
})
