import useUserStore from '~/store/user'
import { AUTH_PAGES_ROUTES, PRIVATE_PAGES_ROUTES } from '~/constants/routes'

const userHasSession = async () => {
  const userStore = useUserStore()
  const hasSession = await userStore.loadUserData()

  if (!hasSession) {
    userStore.resetUser()
    return false
  }

  return true
}

export default defineNuxtRouteMiddleware(async (to) => {
  const privatePagesRoutesList = Object.values(PRIVATE_PAGES_ROUTES)
  const goingToPrivateRoute = privatePagesRoutesList.find((route) => route === to.path)

  if (goingToPrivateRoute) {
    const authorisedUser = await userHasSession()

    if (!authorisedUser) {
      return navigateTo(AUTH_PAGES_ROUTES.LOGIN)
    }
  }
})
